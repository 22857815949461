import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Alert, Button, CardTitle, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Colxx } from '../../components/common/CustomBootstrap';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import { successMessage } from '../../helpers/Utils';
import UserLayout from '../../layout/UserLayout';
import { registerUser } from '../../redux/auth/actions';
import Features from './features';
import './login-styles.css';

const UserSignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, 'Too Short!')
    .required('Please choose a password!'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email address!')
});

class Register extends Component {
  constructor(props) {
    super(props);
  }

  goToLogin = () => {
    this.props.history.push('/user/login');
  };

  getEmail = values => {
    return !this.props.loading && this.props.invite ? this.props.invite.email : values.email;
  };

  onUserRegister = (values, props) => {
    const { registerUser, onStateChange } = this.props;

    registerUser(
      {
        ...values,
        email: this.getEmail(values)
      },
      onStateChange
    )
      .then(() => {
        successMessage('Account created! Please log in.');
        this.goToLogin();
      })
      .catch(err => {
        const response = err && err.response ? err.response.data : {};
        const prop =
          response && response.error && response.error.match(/email/gi) ? 'email' : 'password';

        props.setErrors({
          [prop]: response.error
        });
      })
      .finally(() => {
        props.setSubmitting(false);
      });
  };

  render() {
    const { invite, loading } = this.props;

    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            padding: 40,
            display: 'flex',
            alignItems: 'center',
            background: '#353535',
            borderRadius: 15
          }}
          className='login-cont'
        >
          <div style={{ width: '100%' }}>
            <div
              className='logo-company-name'
              style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}
            >
              <img
                src='https://uploads-ssl.webflow.com/665c883ffef6a1aadd3026d6/665cb41fe59582bc04965238_logo.svg'
                style={{ height: 80 }}
              />
            </div>
            <CardTitle className='mb-4' style={{ color: '#fff' }}>
              Sign Up
            </CardTitle>
            <Formik
              initialValues={{
                email: this.getEmail({ email: '' }),
                password: ''
              }}
              validationSchema={UserSignupSchema}
              onSubmit={this.onUserRegister}
            >
              {props => {
                const { setFieldValue, errors, values, touched } = props;

                return (
                  <Form>
                    <div style={{ color: '#ffee', marginTop: 10 }}>Email</div>
                    <Field component={ReactstrapFormikInput} type='email' name='email' />
                    <div style={{ color: '#ffee', marginTop: 10 }}>Password</div>
                    <Field component={ReactstrapFormikInput} type='password' name='password' />
                    <div className='mt-4 mb-4'>
                      <Button
                        disabled={props.isSubmitting}
                        type='submit'
                        className='t-2'
                        style={{
                          position: 'relative',
                          width: '100%',
                          borderRadius: 5,
                          background: '#b38a23'
                        }}
                      >
                        Create Account
                      </Button>
                      {!invite && (
                        <Button
                          type='reset'
                          onClick={this.goToLogin}
                          color='light'
                          className='mt-2'
                          style={{
                            borderRadius: 5,
                            border: 'solid 1px #b38a23',
                            width: '100%'
                          }}
                        >
                          Sign In instead
                        </Button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.auth.loading || state.loading.models.invites,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    registerUser: (values, onStateChange) => dispatch(registerUser(values, onStateChange))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
