import React from 'react';

function Features() {
  return (
    <>
      <div
        style={{
          background: '#fff',
          backgroundSize: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: '1!important'
        }}
      >
        <div
          style={{
            textTransform: 'uppercase',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
            zIndex: '99999!important'
          }}
        >
          <div
            style={{
              color: '#fff',
              fontSize: '3.5em',
              fontWeight: 800,
              zIndex: 10
            }}
          >
            <span style={{ fontWeight: 600, color: 'black', fontSize: 40 }}>BETTING</span>
            <span style={{ fontWeight: 600, color: 'orange', fontSize: 40, marginLeft: -10 }}>
              SIM
            </span>
          </div>
          <div style={{ color: '#000', zIndex: 10, fontSize: '1.2rem', fontWeight: 600 }}>
            Showcase your betting skills and earn a funded sports betting account.
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
