import React, { Component, Fragment } from 'react';

class UserLayout extends Component {
  render() {
    return (
      <Fragment>
        <div>{this.props.children}</div>
      </Fragment>
    );
  }
}

export default UserLayout;
