import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Alert, Button, CardTitle, Row } from 'reactstrap';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import useToken from '../../helpers/useToken';
import { loginUser } from '../../redux/auth/actions';
import './login-styles.css';

const Login = ({ onStateChange, loginUser, loading, error, history }) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { state } = useLocation();
  const { setToken } = useToken();

  const goToSignUp = () => {
    history.push('/user/register');
  };

  function attemptLogin(values) {
    loginUser(values, onStateChange)
      .then(token => {
        setToken(token);
      })
      .then(() => setRedirectToReferrer(true))
      .catch(err => {
        console.log(err)
      });
  }

  if (redirectToReferrer === true) {
    return <Redirect to={state?.from || '/app'} />;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          padding: 40,
          display: 'flex',
          alignItems: 'center',
          background: '#353535',
          borderRadius: 15
        }}
        className='login-cont'
      >
        <div style={{ width: '100%' }}>
          <div
            className='logo-company-name'
            style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}
          >
            <img
              src='https://uploads-ssl.webflow.com/665c883ffef6a1aadd3026d6/665cb41fe59582bc04965238_logo.svg'
              style={{ height: 80 }}
            />
          </div>
          <CardTitle className='mb-4' style={{ color: '#fff' }}>
            Sign in
          </CardTitle>
          <Formik
            initialValues={{ email: 'testuser@sportsproptech.com', password: `Welcome1'` }}
            onSubmit={values => attemptLogin(values)}
          >
            {props => (
              <Form>
                <span style={{ color: '#ffee' }}>Email</span>
                <Field
                  component={ReactstrapFormikInput}
                  type='email'
                  name='email'
                  required
                  className='mb-4'
                />

                <span style={{ color: '#ffee' }}>Password</span>
                <Field
                  component={ReactstrapFormikInput}
                  type='password'
                  name='password'
                  className='mb-4'
                />

                {error && (
                  <Alert color='danger' style={{ color: '#fff', border: '1px solid red' }}>
                    {error.error}
                  </Alert>
                )}
                <div>
                  <Button
                    className='mt-2'
                    style={{ borderRadius: 5, width: '100%', background: '#b38a23' }}
                    size='lg'
                    type='submit'
                    disabled={loading}
                  >
                    Sign In
                  </Button>
                  <Button
                    type='reset'
                    onClick={goToSignUp}
                    color='light'
                    className='mt-2'
                    disabled={true}
                    style={{
                      borderRadius: 5,
                      border: 'solid 1px #b38a23',
                      width: '100%'
                    }}
                  >
                    Create Account
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (values, onStateChange) =>
      dispatch(loginUser(values.email, values.password, onStateChange))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
